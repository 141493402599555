//Styles
require('../scss/front-page.scss');

//Images
import '../image/icon-compass.png';
import '../image/icon-light.png';
import '../image/icon-support.png';
import '../image/bellwether-mark.png';
import '../image/intro-bg-3b.png';

//JS Imports


//Position the top line